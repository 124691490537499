@import "../../themes/colors.scss";

.MuiTable-root {
    letter-spacing: normal;
    line-height: 1.5;

    // horizontal row highlight
    .MuiTableRow-root:hover {
        .cell {
            &.super-effective {
                background-color: $superEffectiveLight;
            }
    
            &.not-effective {
                background-color: $notEffectiveLight;
            }
    
            &.immune {
                background-color: $immuneLight;
            }
    
            &.blank {
                background-color: $chartCellHighlightLight;
            }
        }
    }

    .MuiTableCell-root {
        background-color: $chartBackground;
        border: 2px solid transparent;
        font-size: 1vw;
        font-weight: bold;
        height: 2vw;
        letter-spacing: normal;
        line-height: 1.5;
        padding: 0.2rem;
        text-align: center;
        min-width: 2.5vw;

        @media (max-width: 1024px) {
            border: 1px solid transparent;
            font-size: 1.35vw;
            height: 2vw;
            width: 2vw;
        }

        &.clickable {
            cursor: pointer;
        }

        &.normal {
            background-color: $normal;
        }
        
        &.fire {
            background-color: $fire;
        }
        
        &.water {
            background-color: $water;
        }
        
        &.electric {
            background-color: $electric;
        }
        
        &.grass {
            background-color: $grass;
        }
        
        &.ice {
            background-color: $ice;
        }
        
        &.fighting {
            background-color: $fighting;
        }
        
        &.poison {
            background-color: $poison;
        }
        
        &.ground {
            background-color: $ground;
        }
        
        &.flying {
            background-color: $flying;
        }
        
        &.psychic {
            background-color: $psychic;
        }
        
        &.bug {
            background-color: $bug;
        }
        
        &.rock {
            background-color: $rock;
        }
        
        &.ghost {
            background-color: $ghost;
        }
        
        &.dragon {
            background-color: $dragon;
        }
        
        &.dark {
            background-color: $dark;
        }
        
        &.steel {
            background-color: $steel;
        }
        
        &.fairy {
            background-color: $fairy;
        }
    }

    .cell {
        border: 2px solid $chartCellBorder;
        border-spacing: 2px;

        @media (max-width: 1024px) {
            border: 1px solid $chartCellBorder;
        }

        &.super-effective {
            background-color: $superEffective;
            color: #FFFFFF;
        }

        &.not-effective {
            background-color: $notEffective;
            color: #FFFFFF;
        }

        &.immune {
            background-color: $immune;
            color: #FFFFFF;
        }

        &.blank {
            background-color: $chartBackground;
        }

        // vertical row highlight
        &.highlighted {
            &.super-effective {
                background-color: $superEffectiveLight;
            }
    
            &.not-effective {
                background-color: $notEffectiveLight;
            }
    
            &.immune {
                background-color: $immuneLight;
            }
    
            &.blank {
                background-color: $chartCellHighlightLight;
            }
        }
        
        &:hover {
            background-color: $chartCellHighlightDark;
            border: 2px solid $pokeballDarkBrown;

            @media (max-width: 1024px) {
                border: 1px solid $pokeballDarkBrown;
            }
        }
    }

    .label {
        font-size: 0.5vw;
        white-space: pre-line;

        @media (max-width: 1024px) {
            font-size: 0.85vw;
        }
    }
}